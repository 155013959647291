<script>
import 'bootstrap/dist/js/bootstrap.min.js'
import HeaderMenu from './components/partials/HeaderMenu.vue'
import SiteFooter from './components/partials/SiteFooter.vue'
import SiteHeader from './components/partials/SiteHeader.vue'
import HomeView from "./HomeView.vue";
import InfoView from "./InfoView.vue";
export default {
  name: "App",
  components: {
    SiteHeader,
    HeaderMenu,
    SiteFooter,
    HomeView,
    InfoView,
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.uid = urlParams.get("uid");
    this.urlImagen = require("@/assets/img/portal_turismo.webp");
    //this.urlImagen = "https://......";
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  data() {
    return {
      uid : null,
      urlImagen : null
    };
  }
};
//Funcion que cargrá los iconos SVGS
(function (a, b, c, d) {
    function e(b, c) {
        if (c) {
            var d = c.getAttribute("viewBox"),e = a.createDocumentFragment(),f = c.cloneNode(true);
            if (d) b.setAttribute("viewBox", d);
            while (f.childNodes.length) e.appendChild(f.childNodes[0]);
            b.appendChild(e);
        }
    }
    function f() {
        var b = this,c = a.createElement("x"),d = b.s;c.innerHTML = b.responseText;
        b.onload = function () {
            d.splice(0).map(function (a) {
                e(a[0], c.querySelector("#" + a[1].replace(/(\W)/g, "\\$1")));
            });
        };
        b.onload();
    }
    function g() {
        var a;
        while ((a = b[0])) {
            var e = a.parentNode,h = a.getAttribute("xlink:href").split("#")[1],
            i = require("./assets/iconos/patron_iconos_ultimo.svg");
            e.removeChild(a);
            var j = (d[i] = d[i] || new XMLHttpRequest());
            if (!j.s) {
                j.s = [];
                j.open("GET", i);
                j.onload = f;
                j.send();
            }
            j.s.push([e, h]);
            if (j.readyState === 4) j.onload();
        }
        c(g);
    }
    g();
})(document, document.getElementsByTagName("use"), window.requestAnimationFrame || window.setTimeout, {});
</script>

<template>

  <header-menu 
  :sitioPrincipal="false"
  :showMenu="false"
  />

  <site-header
    titulo="Bienvenidos a"
    subtitulo="Ministerio de Turismo, Cultura y Deporte"
    :urlImagen = this.urlImagen
    :center="false"
    :fullWidth="false"
    :socialLinks="true"
    :sitioPrincipal="false"
  />

  <div class="content-body">
    <home-view v-if="(this.uid == null)" />
    <info-view v-if="(this.uid != null)" :uid=this.uid />
  </div>

   <site-footer 
  :direcciones="
  [
    {
      titulo: 'Dirección',
      direccion : 'Av. Libertador Gral. San Martín 750 Oeste - Capital. Centro Cívico, 3° Piso, Núcleo 5',
      telefonos : [
        /*
        {
          nombre : 'Teléfono',
          telefono : '26444305928',
          telefonoTexto : '(0264) 430 5928',
        },
        */
        {
          nombre : 'Conmutador',
          telefono : '2644295000',
          telefonoTexto : '(0264) 429-5000',
        },
      ]
    }
  ]"
  />
</template>

<style>
@import url(./assets/css/patron.css);
@import url(./assets/css/customStyles.css);
</style>