<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 5,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <ITCGridComponent
    :columnas="2"
    :data="[
      {
        icon: 'ptn-i-documento',
        target: '_self',
        title: 'Consulta de Expediente',
        url: '?uid=CUMV-VIEW-ExpedientesComponent',
      },
      {
        icon: 'question_answer',
        target: '_self',
        title: 'Consultas y solicitudes',
        url: '?uid=CUMV-VIEW-FormComponent',
      },
    ]"
  />

  <!-- INICIO SECCION BANNER-->
  <section>
    <div class="container">
      <a href="https://sanjuan.tur.ar/"
        ><img src="./assets/img/banner-turismo.jpg" class="img-fluid" />
      </a>
    </div>
  </section>
  <!-- FIN SECCION BANNER-->

  <NewsComponent titulo="Noticias" />

  <FrecuentAccessComponent
    titulo="Teléfonos útiles"
    :data="[
      {
        title: 'Ministerio de Turismo y Cultura',
        links: [
          {
            target: '_blank',
            title: '(264) 430 5000',
            url: 'tel:2644305000',
          },
        ],
      },
      {
        title: 'Oficina de Turismo San Juan',
        links: [
          {
            target: '_blank',
            title: '(0264) 421 00044',
            url: 'tel:26442100044',
          },
        ],
      },
      {
        title: 'Aeropuerto Domingo Faustino Sarmiento (UAQ)',
        links: [
          {
            target: '_blank',
            title: '(264) 425 4133',
            url: 'tel:2644254133',
          },
        ],
      },
      {
        title: 'Estación Terminal de Ómnibus',
        links: [
          {
            target: '_blank',
            title: '(0264) 422 5147',
            url: 'tel:2644225147',
          },
          {
            target: '_blank',
            title: '(0264) 422 1604',
            url: 'tel:2644221604',
          },
        ],
      },
      {
        title: 'Hospital Guillermo Rawson',
        links: [
          {
            target: '_blank',
            title: '(0264) 422 4005',
            url: 'tel:2644224005',
          },
          {
            target: '_blank',
            title: '(0264) 422 4022',
            url: 'tel:2644224022',
          },
          {
            target: '_blank',
            title: '(0264) 422 2272',
            url: 'tel:2644222272',
          },
        ],
      },
    ]"
  />
  <!-- 
  <BoxIconGridComponent
    :iconos-grandes="true"
    titulo="¿Cómo me contacto?"
    :filtros="{ site: this.site }"
  /> -->

  <section>
    <div class="container">
      <h2 class="pb-2">Redes Sociales</h2>
      <br />
      <div class="row">
        <div class="col-sm-2">
          <a
            href="https://www.facebook.com/prensaturismo.sanjuan/?fref=ts"
            target="_blank"
            ><h1 class="text-center">
              <svg
                class="svg-inline--fa fa-facebook-square fa-w-14 ptn-i-2x"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
            </h1>
            <p class="tituloscuatro text-center">
              Ministerio de Turismo y Cultura
            </p></a
          >
        </div>
        <div class="col-sm-2">
          <a
            href="https://www.facebook.com/TBicentenarioSanJuan"
            target="_blank"
            ><h1 class="text-center">
              <svg
                class="svg-inline--fa fa-facebook-square fa-w-14 ptn-i-2x"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
            </h1>
            <p class="tituloscuatro text-center">Teatro del Bicentenario</p></a
          >
        </div>
        <div class="col-sm-2">
          <a href="https://www.facebook.com/FNSolOficial" target="_blank"
            ><h1 class="text-center">
              <svg
                class="svg-inline--fa fa-facebook-square fa-w-14 ptn-i-2x"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
            </h1>
            <p class="tituloscuatro text-center">Fiesta Nacional del Sol</p></a
          >
        </div>
        <div class="col-sm-2">
          <a
            href="https://www.facebook.com/Museo-Franklin-Rawson-361939737164260/?fref=ts"
            target="_blank"
            ><h1 class="text-center">
              <svg
                class="svg-inline--fa fa-facebook-square fa-w-14 ptn-i-2x"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
            </h1>
            <p class="tituloscuatro text-center">Museo Franklin Rawson</p></a
          >
        </div>
        <div class="col-sm-2">
          <a href="https://www.facebook.com/cccontegrand/" target="_blank"
            ><h1 class="text-center">
              <svg
                class="svg-inline--fa fa-facebook-square fa-w-14 ptn-i-2x"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
            </h1>
            <p class="tituloscuatro text-center">
              Centro Cultural Conte Grand
            </p></a
          >
        </div>
      </div>
    </div>
  </section>

  <AutoriryComponent
    :showProtocolo="false"
    :showLinks="true"
    :data="[
      {
        title: 'Guido Romero',
        subtitle: 'Ministro de Turismo, Cultura y Deporte',
        imagen: require('@/assets/img/mindeturismoculturadeportes.jpg'),
      },
    ]"
  />
</template>
